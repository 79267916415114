
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import XmlController from "@/plugins/uyap-plugin/takip_ac/XmlController";
import { VForm } from "@/types";
import * as xml2js from "xml2js";
import { JobType } from "@/enum/JobType";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import { ProgramPaths } from "@/enum/ProgramPaths";

interface JobItem {
  id: number;
  file_name: string;
  created_at: string;
  file_count: number;
  showProgress?: boolean;
  value?: number;
  faiz_turu_var_mi?: boolean;
}

interface Kademe {
  otzid: string;
  ottarih: string;
  ot2oran: string;
  ot2faizid: string;
}

interface Faiz {
  faiz_adi: string;
  faiz_id: string;
  faiz_aylik: string;
}

interface FaizGrup {
  faiz_kademeleri: Kademe[];
  faiz_listesi: Faiz[];
}

interface FaizTurleriResponse {
  faiz_turleri: {
    faiz: Faiz | Faiz[];
    faiz_kademeleri: {
      kademe?: {
        ot2id: string;
        ot2tarih: string;
        ot2oran: string;
        ot2faizid: string;
      } | Array<{
        ot2id: string;
        ot2tarih: string;
        ot2oran: string;
        ot2faizid: string;
      }>;
    }[];
  };
}

interface ResultType {
  faiz_turleri: FaizGrup[];
}

@Component({
  components: { DeleteDialog }
})
export default class AktarView extends Vue {
  @Ref("form") readonly xmlForm!: VForm;
  valid = false;
  onFly = false;
  xml: File | null = null;
  xmlFiles: File[] = [];
  items: JobItem[] = [];
  loadItems = false;
  interval: any = {};
  value = 60;
  showProgress = false;
  fileName: string = "";
  uploadProgress = 0;
  alert = false;
  alertMessage = "";
  showDialog = false;
  showSecondaryDialog = false;
  tab = 0;
  countdown: number = 120;
  progress: number = 100;
  errorFiles: { fileName: string; message: string }[] = [];
  currentFileIndex: number = 0;
  fileQueue: File[] = [];
  dialogTitle: string = "";
  dialogMessage: string = "";
  dialogConfirmText: string = "Evet";
  dialogDismissText: string = "Hayır";
  dialogConfirmAction: (() => void) | null = null;
  dialogDismissAction: (() => void) | null = null;
  currentFileName: string = "Yükleme hazırlanıyor...";
  currentFileStatus: string = "Lütfen bekleyin...";
  fileStatuses: { [key: string]: 'success' | 'error' | 'pending' } = {};
  evrakStatuses: { [key: string]: 'success' | 'error' | 'pending' } = {};
  currentStep: number = 1;
  currentChunk: number = 0;
  totalChunks: number = 0;
  chunkProgress: number = 0;
  emptyTag: undefined;
  successCount: number = 0;
  errorCount: number = 0;
  timer: ReturnType<typeof setInterval> | null = null;


  headers = [
    { text: "İşlem No", value: "id" },
    { text: "Dosya Adı", value: "file_name" },
    { text: "Oluşturulma Tarihi", value: "created_at" },
    { text: "Dosya Sayısı", value: "file_count" },
    { text: "İşlemler", value: "actions", align: "end" }
  ];

  mounted() {
    this.load();
    window.addEventListener("beforeunload", this.preventNavigation);
    window.addEventListener("click", this.showSnackbarOnInteraction);
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNavigation);
    window.removeEventListener("click", this.showSnackbarOnInteraction);
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  @Watch("xml")
  onXmlChanged(newValue: File | null, oldValue: File | null) {
    if (newValue) {
      this.fileName = newValue.name;
      this.autoUpload();
    }
  }

  @Watch("showDialog")
  onShowDialogChanged(newVal: boolean) {
    if (newVal) {
      this.startCountdown();
    } else {
      this.resetCountdown();
    }
  }

  async load() {
    this.loadItems = true;
    try {
      const [response, evrak] = await Promise.all([
        this.$http.get("/api/v1/job?type_id=2"),
        this.$http.get("/api/v1/job?type_id=3")
      ]);
      // @ts-ignore
      this.items = [...response, ...evrak];
    } catch (error) {
      console.error('Error loading items:', error);
    } finally {
      this.loadItems = false;
    }
  }

  setProgress(item: JobItem) {
    this.items.forEach((item) => {
      this.$set(item, "showProgress", false);
      this.$set(item, "value", 60);
    });

    this.$set(item, "showProgress", true);
    const interval = setInterval(() => {
      if (item.value === 0) {
        clearInterval(interval);
        item.showProgress = false;
      }
      item.value! -= 1;
    }, 1000);
  }

  fileRule(file: File | null): boolean | string {
    if (!file) return "Lütfen bu alanı doldurun.";
    const fileName = file.name || '';
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    if (fileExtension === 'xml') return true;
    return "Sadece xml dosya yüklenebilir.";
  }

  bolArray(dizi: Array<any>, boyut: number): Array<Array<any>> {
    let sonuc: Array<Array<any>> = [];
    for (let i = 0; i < dizi.length; i += boyut) {
      sonuc.push(dizi.slice(i, i + boyut));
    }
    return sonuc;
  }

  async xmlToJson(xmlData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const parser = new xml2js.Parser({
        explicitArray: false,
        mergeAttrs: true,
        emptyTag: undefined
      });
      parser.parseString(xmlData, (err: any, result: any) => {
        if (err) {
          console.error("XML çevrim hatası:", err);
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  detay(item: JobItem) {
    this.$router.push(ProgramPaths.ofispro + "/aktar-detay/" + item.id);
  }

  async onFileChange(files: File[]) {
    if (files && files.length && !this.onFly) {
      this.xmlFiles = files;
      this.fileName = files[0].name;
      console.log("Dosyalar seçildi:", this.xmlFiles);
      this.errorFiles = [];
      files.forEach(file => {
        this.$set(this.fileStatuses, file.name, 'pending');
        this.$set(this.evrakStatuses, file.name, 'pending');
      });
    }
  }

  onDrop(event: DragEvent) {
    const files = event.dataTransfer!.files;
    if (files.length) {
      this.xmlFiles = Array.from(files);
      this.autoUpload();
    }
  }

  preventNavigation(event: BeforeUnloadEvent) {
    if (this.onFly) {
      event.preventDefault();
      event.returnValue = "";
    }
  }

  showSnackbarOnInteraction(event: Event) {
    if (this.onFly) {
      event.preventDefault();
    }
  }

  showCompletionDialog() {
    this.dialogTitle = "Dosya Aktarımı Tamamlandı";
    this.dialogMessage = "Dosya aktarımı tamamlandı. Evrak aktarımına devam etmek istiyor musunuz?";
    this.dialogConfirmText = "Evet";
    this.dialogDismissText = "Hayır";
    this.dialogConfirmAction = this.continueToEvrakTransfer;
    this.dialogDismissAction = this.finishProcess;
    this.showDialog = true;
  }

  handleDialogConfirm() {
    this.showDialog = false;
    if (this.dialogConfirmAction) {
      this.dialogConfirmAction();
    }
  }

  handleDialogDismiss() {
    this.showDialog = false;
    if (this.dialogDismissAction) {
      this.dialogDismissAction();
    }
  }

  async continueToEvrakTransfer() {
    this.tab = 1;
    await this.$nextTick();

    if (this.xmlFiles && this.xmlFiles.length > 0) {
      await this.xmlEvrakAktarMultiple();
    } else {
      this.$toast.info("Lütfen önce dosya seçiniz");
    }
  }

  finishProcess() {
    this.showSecondaryDialog = true;
  }

  async autoUpload() {
    if (this.onFly || this.xmlFiles.length === 0) {
      console.log("İşlem zaten devam ediyor veya dosya yok");
      return;
    }

    console.log("autoUpload başladı");

    try {
      if (this.tab === 0) {
        console.log("xmlAktarMultiple başlatılıyor");
        await this.xmlAktarMultiple();
      } else if (this.tab === 1) {
        console.log("xmlEvrakAktarMultiple başlatılıyor");
        await this.xmlEvrakAktarMultiple();
      }
    } catch (error) {
      console.error("Dosya yükleme hatası:", error);
      this.$toast.error("Dosya yükleme sırasında bir hata oluştu: " + error.message);
    }
  }

  showUploadStatus() {
    if (this.xmlFiles.length === 0) return;

    const totalFiles = this.xmlFiles.length;
    const processedFiles = this.currentFileIndex;
    const remainingFiles = totalFiles - processedFiles;
    const successFiles = this.successCount;
    const errorFiles = this.errorCount;

    this.$toast.info(
        `İşlenen: ${processedFiles}, Başarılı: ${successFiles}, Hatalı: ${errorFiles}, Kalan: ${remainingFiles}, Toplam: ${totalFiles}`
    );
  }

  async readXmlWithTimeout(file: File, timeout: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 1024 * 1024;
      let offset = 0;
      let result = '';
      const fileReader = new FileReader();
      const timer = setTimeout(() => {
        fileReader.abort();
        reject(new Error("Dosya okuma zaman aşımına uğradı"));
      }, timeout);

      fileReader.onload = (e) => {
        result += e.target?.result as string;
        offset += chunkSize;
        if (offset >= file.size) {
          clearTimeout(timer);
          resolve(result);
        } else {
          readNextChunk();
        }
      };

      fileReader.onerror = (e) => {
        clearTimeout(timer);
        reject(new Error("Dosya okuma hatası"));
      };

      const readNextChunk = () => {
        const slice = file.slice(offset, offset + chunkSize);
        fileReader.readAsText(slice);
      };

      readNextChunk();
    });
  }

  showBrokenXmlDialog() {
    this.alertMessage = "Bozuk XML dosyası yüklenemedi. Lütfen dosyanızı kontrol edin ve tekrar deneyin.";
    this.alert = true;
  }

  async postRequestWithRetry(url: string, data: any, delayMs = 5000) {
    while (true) {
      try {
        const response = await this.$http.post(url, data);
        if (response && response.data && response.data.args && response.data.args.length > 0) {
          return response.data;
        }
        throw new Error("No response args or args is empty");
      } catch (error) {
        console.error("Error in postRequestWithRetry:", error);
        await this.delay(delayMs);
      }
    }
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async xmlAktarMultiple() {
    if (this.onFly) {  console.warn("Uyarı: Bir işlem zaten devam ediyor.");
      return false;
    }

    console.log("xmlAktarMultiple başladı");
    this.errorFiles = [];
    this.currentFileIndex = 0;
    this.onFly = true;

    this.xmlFiles.forEach(file => {
      this.$set(this.fileStatuses, file.name, 'pending');
    });

    try {
      if (!this.xmlFiles || this.xmlFiles.length === 0) {
        throw new Error("Yüklenecek dosya seçilmedi");
      }

      const totalFiles = this.xmlFiles.length;

      for (let i = 0; i < totalFiles; i++) {
        const file = this.xmlFiles[i];

        this.updateUIStatus(file.name, i, totalFiles);

        this.xml = file;
        this.fileName = file.name;

        try {
          await this.xmlAktar();

          this.$set(this.fileStatuses, file.name, 'success');
          console.log(`${file.name} başarıyla işlendi`);
        } catch (error) {
          this.$set(this.fileStatuses, file.name, 'error');
          this.errorFiles.push({
            fileName: file.name,
            message: error.message || "Bilinmeyen bir hata oluştu"
          });
          console.error(`${file.name} işlenirken hata:`, error);
        }

        const completedFiles = i + 1;
        this.uploadProgress = (completedFiles / totalFiles) * 100;

        await this.$nextTick();
        await this.load();
      }

      this.updateUIStatus("İşlem tamamlandı", totalFiles, totalFiles);
      if (this.errorFiles.length === 0) {
        this.showCompletionDialog();
      } else {
        this.$toast.warning(`İşlem tamamlandı. ${this.errorFiles.length} dosya hatalı.`);
      }

      return true;
    } catch (error) {
      console.error("Genel işlem hatası:", error);
      this.$toast.error("Dosya işleme sırasında hata oluştu: " + error.message);
      return false;
    } finally {
      this.onFly = false;
      this.uploadProgress = 100;
    }
  }

  async xmlAktar() {
    if (!this.xml) {
      console.error("Dosya seçilmedi");
      return;
    }

    try {
      this.uploadProgress = 0;
      this.chunkProgress = 0;
      console.log(`Başlıyor: ${this.fileName} dosyası okunacak`);

      const timeout = Math.max(300000, this.xml.size / 1024 / 10);
      let xmlContent;
      let faizTuruVarMi = false;

      try {
        this.currentStep = 1;
        this.updateUIStatus(`${this.fileName} okunuyor...`, this.currentFileIndex, this.xmlFiles.length);
        await this.$nextTick();
        xmlContent = await this.readXmlWithTimeout(this.xml, timeout);
        console.log(`${this.fileName} dosyası başarıyla okundu`);
      } catch (error) {
        console.error("Dosya okuma hatası:", error);
        this.showBrokenXmlDialog();
        throw error;
      }

      let data;
      try {
        this.currentStep = 2;
        this.updateUIStatus(`${this.fileName} işleniyor...`, this.currentFileIndex, this.xmlFiles.length);
        await this.$nextTick();
        data = await this.optimizeXmlParsing(xmlContent);
        if (!data || !data.exchangeData) {
          throw new Error("Geçersiz XML yapısı: exchangeData bulunamadı.");
        }
        data = data.exchangeData;
        console.log(`${this.fileName} XML verisi JSON'a dönüştürüldü`, data);

        if (data.faiz_turleri) {
          faizTuruVarMi = Array.isArray(data.faiz_turleri.faiz)
              ? data.faiz_turleri.faiz.length > 0
              : !!data.faiz_turleri.faiz;
        }
        console.log(`faizTuruVarMi for ${this.fileName}:`, faizTuruVarMi);
      } catch (error) {
        console.error("XML verisi JSON'a dönüştürülürken hata oluştu:", error);
        this.showBrokenXmlDialog();
        throw error;
      }

      if (!data.dosyalar || !data.dosyalar.dosya) {
        throw new Error("Geçersiz dosya yapısı: dosyalar veya dosya bulunamadı");
      }

      let dosyalar = Array.isArray(data.dosyalar.dosya)
          ? data.dosyalar.dosya
          : [data.dosyalar.dosya];

      dosyalar = dosyalar.map((dosya: any) => {
        if (!dosya) return null;
        const { evraklar, ...rest } = dosya;
        return rest;
      }).filter(Boolean);

      if (dosyalar.length === 0) {
        throw new Error("İşlenecek dosya bulunamadı");
      }

      this.currentStep = 3;
      this.updateUIStatus(`${this.fileName} aktarılıyor...`, this.currentFileIndex, this.xmlFiles.length);
      await this.$nextTick();
      let bolunmusDosyalar = this.bolArray(dosyalar, 25);
      console.log(`${bolunmusDosyalar.length} parça işlenecek`);

      const totalChunks = bolunmusDosyalar.length;
      this.totalChunks = totalChunks;
      let uploadedChunks = 0;

      for (let bolunmusDosya of bolunmusDosyalar) {
        this.currentChunk = uploadedChunks + 1;
        if (!bolunmusDosya || bolunmusDosya.length === 0) {
          continue;
        }
        this.updateUIStatus(`${this.fileName} - Parça ${this.currentChunk}/${this.totalChunks} işleniyor...`, this.currentFileIndex, this.xmlFiles.length);
        await this.$nextTick();
        let dosyaArgs = bolunmusDosya.map(dosya => ({
          ...dosya,
          kart_no: dosya.kart_no,
          faiz_turu_var_mi: faizTuruVarMi
        }));
        let success = false;
        let retries = 3;
        while (retries > 0 && !success) {
          try {
            let response = await this.$http.post('/api/v1/job', {
              file_name: this.fileName,
              type_id: JobType.IcwAktar,
              status: "WAITING",
              args: dosyaArgs,
              faiz_turu_var_mi: faizTuruVarMi
            });
            success = true;
            console.log("Başarıyla gönderildi:", response);
          } catch (error) {
            retries--;
            console.error("Gönderim hatası:", error);
            if (retries === 0) {
              this.$toast.error("Dosya parçası gönderilirken hata oluştu: " + (error.response ? error.response.data.error : error.message));
            }
          }
        }
        uploadedChunks++;
        this.chunkProgress = (uploadedChunks / totalChunks) * 100;
        await this.$nextTick();
      }

      const faizTurleri = (data: FaizTurleriResponse): ResultType[] => {
        try {
          const result: ResultType[] = [{
            faiz_turleri: [{
              faiz_kademeleri: [],
              faiz_listesi: []
            }]
          }];

          if (!data?.faiz_turleri) {
            console.warn("Veri yapısı eksik");
            return result;
          }

          const faizler: Faiz[] = [];
          if (Array.isArray(data.faiz_turleri.faiz)) {
            faizler.push(...data.faiz_turleri.faiz);
          } else if (data.faiz_turleri.faiz) {
            faizler.push(data.faiz_turleri.faiz);
          }

          result[0].faiz_turleri[0].faiz_listesi = faizler.map((faiz): Faiz => ({
            faiz_adi: faiz?.faiz_adi || "",
            faiz_id: faiz?.faiz_id || "",
            faiz_aylik: faiz?.faiz_aylik || ""
          }));

          const tumKademeler: Kademe[] = [];
          const kademelerList = data.faiz_turleri.faiz_kademeleri;
          if (kademelerList) {
            Object.values(kademelerList).forEach(kademelerGrubu => {
              if (!kademelerGrubu?.kademe) return;

              const kademeler = Array.isArray(kademelerGrubu.kademe)
                  ? kademelerGrubu.kademe
                  : [kademelerGrubu.kademe];

              kademeler.forEach(kademe => {
                if (kademe?.ot2id) {
                  tumKademeler.push({
                    otzid: kademe.ot2id || "",
                    ottarih: kademe.ot2tarih || "",
                    ot2oran: kademe.ot2oran || "",
                    ot2faizid: kademe.ot2faizid || ""
                  });
                }
              });
            });
          }
          result[0].faiz_turleri[0].faiz_kademeleri = tumKademeler;
          return result;

        } catch (error) {
          console.error("Veri işleme hatası:", error);
          return [{
            faiz_turleri: [{
              faiz_kademeleri: [],
              faiz_listesi: []
            }]
          }];
        }
      };

      let faizVerisi = null;
      if (data.faiz_turleri) {
        faizVerisi = faizTurleri(data);
        console.log("İşlenmiş faiz verileri:", faizVerisi);

        // Faiz verilerinin ayrı bir job olarak gönderimi
        try {
          await this.$http.post('/api/v1/job', {
            file_name: this.fileName,
            type_id: JobType.IcwAktarFaiz,
            status: "WAITING",
            args: faizVerisi,
            faizTuruVarMi, faizTuruVarMi
          });
          console.log("Faiz verileri başarıyla gönderildi");
        } catch (error) {
          console.error("Faiz verileri gönderilirken hata oluştu:", error);
          this.$toast.error("Faiz verileri gönderilirken hata oluştu: " +
              (error.response ? error.response.data.error : error.message));
        }
      }

      await this.load();

      if (this.items.length > 0) {
        this.setProgress(this.items[this.items.length - 1]);
      }

      this.$toast.success(`${this.fileName} dosyası için Xml'den İcrapro'ya aktarma işlemi sıraya alındı.`);
    } catch (error) {
      console.error(`${this.fileName} için xmlAktar hatası:`, error);
      this.$toast.error(`${this.fileName} işleme sırasında hata oluştu: ` + error.message);
      this.fileStatuses[this.fileName] = 'error';
      throw error;
    }
  }

  updateUIStatus(status: string, currentIndex: number, totalFiles: number) {
    this.currentFileName = this.fileName;
    this.currentFileStatus = status;
    this.currentFileIndex = currentIndex;
    this.uploadProgress = ((currentIndex + 1) / totalFiles) * 100;
  }

  async xmlEvrakAktarMultiple() {
    if (this.onFly) {
      this.$toast.warning("İşlem zaten devam ediyor");
      return false;
    }

    try {
      console.log("xmlEvrakAktarMultiple başladı");

      if (!this.xmlFiles || this.xmlFiles.length === 0) {
        this.$toast.error("Lütfen evrak dosyası seçiniz");
        return false;
      }

      this.errorFiles = [];
      this.currentFileIndex = 0;
      this.onFly = true;

      // Her dosya için durumu sıfırla
      this.xmlFiles.forEach(file => {
        this.$set(this.evrakStatuses, file.name, 'pending');
      });

      // Her dosyayı sırayla işle
      for (const file of this.xmlFiles) {
        try {
          console.log(`${file.name} evrak işleniyor...`);
          this.xml = file;
          this.fileName = file.name;
          this.currentFileName = file.name; // Dosya adını güncelle

          // XML içeriğini oku
          const xmlContent = await this.readXmlWithTimeout(file, Math.max(300000, file.size / 1024 / 10));
          if (!xmlContent) {
            throw new Error("XML içeriği okunamadı");
          }

          // XML'i parse et
          const data = await this.optimizeXmlParsing(xmlContent);
          if (!data?.exchangeData?.dosyalar?.dosya) {
            throw new Error("Geçersiz XML yapısı");
          }

          // Evrakları hazırla
          const dosyalar = Array.isArray(data.exchangeData.dosyalar.dosya)
              ? data.exchangeData.dosyalar.dosya
              : [data.exchangeData.dosyalar.dosya];

          let evraklar: any[] = [];
          for (const dosya of dosyalar) {
            if (dosya?.evraklar?.evrak) {
              const dosyaEvraklar = Array.isArray(dosya.evraklar.evrak)
                  ? dosya.evraklar.evrak
                  : [dosya.evraklar.evrak];

              evraklar.push(...dosyaEvraklar.map((evrak: any) => ({
                ...evrak,
                kart_no: dosya.kart_no
              })));
            }
          }

          if (evraklar.length === 0) {
            throw new Error("İşlenecek evrak bulunamadı");
          }

          // Evrakları gönder
          await this.sendEvraklar(evraklar, file.name, data); // Pass data to sendEvraklar

          // Başarılı işlem
          this.$set(this.evrakStatuses, file.name, 'success');
          console.log(`${file.name} başarıyla işlendi`);

          // UI Güncelle
          await this.$nextTick();
          await this.load();

        } catch (error) {
          console.error(`${file.name} işlenirken hata:`, error);
          this.$set(this.evrakStatuses, file.name, 'error');
          this.errorFiles.push({
            fileName: file.name,
            message: error.message || "Bilinmeyen bir hata oluştu"
          });
        }

        // İlerleme durumunu güncelle
        this.currentFileIndex++;
        this.uploadProgress = (this.currentFileIndex / this.xmlFiles.length) * 100;

        // Dosyalar arası bekle
        if (this.currentFileIndex < this.xmlFiles.length) {
          await this.delay(2000);
        }
      }

      // İşlem sonucu
      if (this.errorFiles.length === 0) {
        this.$toast.success("Tüm evraklar başarıyla aktarıldı");
      } else {
        this.$toast.warning(`İşlem tamamlandı. ${this.errorFiles.length} evrak hatalı`);
      }

      return true;
    } catch (error) {
      console.error("Genel işlem hatası:", error);
      this.$toast.error("Evrak aktarma işlemi sırasında hata oluştu: " + error.message);
      return false;
    } finally {
      this.onFly = false;
      this.uploadProgress = 0;
      this.chunkProgress = 0;
      this.currentStep = 1;
      await this.load();
    }
  }

  private async sendEvraklar(evraklar: any[], fileName: string, data: any): Promise<void> { // Added data parameter
    try {
      this.currentStep = 3;
      this.currentFileStatus = "Evraklar gönderiliyor...";
      await this.$nextTick();

      // Daha küçük parçalara böl ve sıralı işle
      const maxChunkSize = 1; // Tek tek gönder
      const chunks = this.bolArray(evraklar, maxChunkSize);

      this.totalChunks = chunks.length;
      this.currentChunk = 0;

      for (const [index, chunk] of chunks.entries()) {
        this.currentChunk = index + 1;
        this.chunkProgress = ((index + 1) / chunks.length) * 100;

        let retryCount = 0;
        const maxRetries = 3;
        let success = false;

        while (!success && retryCount < maxRetries) {
          try {
            // Her istek öncesi bekle
            await this.delay(1000); // 1 saniye bekle

            // İstek gönder
            const response = await this.$http.post('/api/v1/job', {
              file_name: fileName,
              type_id: JobType.IcwAktarDosya,
              status: "WAITING",
              args: chunk,
              faiz_turu_var_mi: !!data.exchangeData.faiz_turleri // Added faiz_turu_var_mi
            });

            // Yanıt kontrolü
            if (response?.data) {
              // Yanıt içeriğini kontrol et
              if (this.validateResponse(response.data)) {
                success = true;
                console.log(`Parça ${this.currentChunk}/${this.totalChunks} başarıyla gönderildi`);
              } else {
                throw new Error("Geçersiz sunucu yanıtı yapısı");
              }
            } else {
              throw new Error("Sunucudan yanıt alınamadı");
            }

          } catch (error) {
            retryCount++;
            console.error(`Deneme ${retryCount}/${maxRetries}:`, error);

            if (error.response?.status === 413) {
              throw new Error("Beklenmeyen 413 hatası: Parça boyutu zaten 1");
            }

            if (retryCount === maxRetries) {
              throw error;
            }

            // Üstel geri çekilme ile bekle
            await this.delay(Math.min(1000 * Math.pow(2, retryCount), 10000));
          }
        }

        // Her başarılı gönderimden sonra bekle
        await this.delay(500);

        // Her 10 parçada bir daha uzun bekle
        if (this.currentChunk % 10 === 0) {
          await this.delay(2000);
        }
      }
    } catch (error) {
      throw new Error(`Evrak gönderme hatası: ${this.getErrorMessage(error)}`);
    }
  }

  private validateResponse(response: any): boolean {
    // Temel yanıt yapısı kontrolü
    if (!response) return false;

    // args kontrolü
    if (!Array.isArray(response.args)) {
      console.warn("Yanıt args dizisi içermiyor");
      return false;
    }

    // Diğer gerekli alanların kontrolü
    if (!response.file_name || !response.type_id || !response.status) {
      console.warn("Yanıt gerekli alanları içermiyor");
      return false;
    }

    return true;
  }

  private getErrorMessage(error: any): string {
    if (typeof error === 'string') return error;

    if (error.response?.data?.error) {
      return error.response.data.error;
    }

    if (error.message) {
      return error.message;
    }

    return "Bilinmeyen bir hata oluştu";
  }

  async optimizeXmlParsing(xmlContent: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const parser = new xml2js.Parser({
        explicitArray: false,
        mergeAttrs: true,
        emptyTag: undefined,
        async: true
      });
      parser.parseString(xmlContent, (err: any, result: any) => {
        if (err) {
          console.error("XML çevrim hatası:", err);
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  startCountdown() {
    this.countdown = 120;
    this.progress = 100;
    this.timer = setInterval(() => {
      this.countdown--;
      this.progress = (this.countdown / 120) * 100;
      if (this.countdown <= 0) {
        clearInterval(this.timer!);
        this.autoDismiss();
      }
    }, 1000);
  }

  resetCountdown() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.progress = 100;
  }

  autoDismiss() {
    this.showDialog = false;
    this.showSecondaryDialog = true;
  }

  removeFile(index: number) {
    const fileName = this.xmlFiles[index].name;
    delete this.fileStatuses[fileName];
    delete this.evrakStatuses[fileName];
    this.xmlFiles.splice(index, 1);
  }

  formatFileSize(size: number): string {
    const units = ['B', 'KB', 'MB', 'GB'];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  }

  async anotherProcess() {
    this.showSecondaryDialog = false;
  }

  goHome() {
    this.showSecondaryDialog = false;
    this.$router.push('/ofispro');
  }

  get fileSuccessCount() {
    return Object.values(this.fileStatuses).filter(status => status === 'success').length;
  }

  get filePendingCount() {
    return Object.values(this.fileStatuses).filter(status => status === 'pending').length;
  }

  get fileErrorCount() {
    return Object.values(this.fileStatuses).filter(status => status === 'error').length;
  }

  get evrakSuccessCount() {
    return Object.values(this.evrakStatuses).filter(status => status === 'success').length;
  }

  get evrakPendingCount() {
    return Object.values(this.evrakStatuses).filter(status => status === 'pending').length;
  }

  get evrakErrorCount() {
    return Object.values(this.evrakStatuses).filter(status => status === 'error').length;
  }

  get pendingEvrakCount() {
    return this.xmlFiles.filter(file => this.evrakStatuses[file.name] === 'pending').length;
  }

  get getProgressText() {
    if (this.tab === 0) {
      return `Dosya İşleniyor (${this.currentChunk}/${this.totalChunks} parça)`;
    } else {
      return `Evrak İşleniyor (${this.currentChunk}/${this.totalChunks} parça)`;
    }
  }
}
